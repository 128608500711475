<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">
              Ticket Number : {{ detail.no_ticket }}
            </h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <h5 class="border-bottom pb-2">
                  Check List {{ detail.type_ticket }} Maintenance
                </h5>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12 mb-2">
                    <label class="mb-0">Ticket Number :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.no_ticket }}</strong>
                    </div>
                  </div>
                  <div class="col-md-12 mb-2">
                    <label class="mb-0">Date :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ dateFormat(detail.ticket_created) }}</strong>
                    </div>
                  </div>
                  <div v-if="detail.nama_area" class="col-md-12 mb-2">
                    <label class="mb-0">Area :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_area }}</strong>
                    </div>
                  </div>
                  <div v-if="detail.nama_lokasi" class="col-md-12 mb-2">
                    <label class="mb-0">Location :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_lokasi }}</strong>
                    </div>
                  </div>
                  <div v-if="detail.nama_sublokasi" class="col-md-12 mb-2">
                    <label class="mb-0">Sub Location :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_sublokasi }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div v-if="detail.nama_device" class="col-md-12 mb-2">
                    <label class="mb-0">Device Name :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_device }}</strong>
                    </div>
                  </div>
                  <div v-if="detail.nama_brand" class="col-md-12 mb-2">
                    <label class="mb-0">Device Brand :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_brand }}</strong>
                    </div>
                  </div>
                  <div v-if="detail.nama_type" class="col-md-12 mb-2">
                    <label class="mb-0">Device Type :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_type }}</strong>
                    </div>
                  </div>
                  <div v-if="detail.sn_device" class="col-md-12 mb-2">
                    <label class="mb-0">Device SN :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.sn_device }}</strong>
                    </div>
                  </div>
                  <div class="col-md-12 mb-2">
                    <label class="mb-0">Ticket Status :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.status_ticket }}</strong>
                    </div>
                  </div>
                  <div v-if="detail.ticket_progress" class="col-md-12 mb-2">
                    <label class="mb-0">Ticket Progress :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.ticket_progress }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h5 class="border-bottom pb-2">Item Maintenance</h5>
              </div>
              <div class="col-md-12">
                <div
                  class="form-group"
                  v-for="(items, category) in groupedMaintenance"
                  :key="category"
                >
                  <label class="f-w-600">{{ category }}</label>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="widget-list widget-list-rounded mb-2">
                        <a
                          v-for="item in items"
                          :key="item.id_maintenance"
                          href="javascript:;"
                          class="widget-list-item rounded-0 p-t-3"
                        >
                          <div
                            class="widget-list-media icon"
                            v-if="detail.ticket_progress"
                          >
                            <span class="checkbox checkbox-css">
                              <input
                                type="checkbox"
                                :id="'cssCheckbox' + item.id_maintenance"
                                checked
                                disabled
                              />
                              <label :for="'cssCheckbox' + item.id_maintenance">
                                <span class="text-light">.</span>
                              </label>
                            </span>
                          </div>
                          <div class="widget-list-media icon" v-else>
                            <span class="checkbox checkbox-css">
                              <input
                                type="checkbox"
                                :id="'cssCheckbox' + item.id_maintenance"
                                v-model="item.pilih"
                                @change="
                                  updateSelectedData(item.id_maintenance)
                                "
                              />
                              <label :for="'cssCheckbox' + item.id_maintenance">
                                <span class="text-light">.</span>
                              </label>
                            </span>
                          </div>
                          <label
                            class="widget-list-content"
                            :for="'cssCheckbox' + item.id_maintenance"
                          >
                            <div class="widget-list-title">
                              <span>{{ item.item_maintenance }}</span>
                              <div
                                class="row mt-2"
                                v-if="userLevel == 'Koordinator'"
                              >
                                <div class="col-md-2">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-xs btn-primary"
                                    @click="
                                      triggerFileInput(item.id_maintenance)
                                    "
                                  >
                                    Upload Image
                                  </button>
                                  <input
                                    type="file"
                                    :id="'fileInput' + item.id_maintenance"
                                    class="d-none"
                                    @change="
                                      uploadImage($event, item.id_maintenance)
                                    "
                                  />
                                </div>
                                <div
                                  class="col-md-10"
                                  v-if="uploadProgress[item.id_maintenance]"
                                >
                                  <b-progress
                                    :value="
                                      uploadProgress[item.id_maintenance] || 0
                                    "
                                    :max="100"
                                    show-progress
                                    animated
                                  ></b-progress>
                                </div>
                              </div>
                              <div
                                class="row mt-2"
                                v-if="userLevel == 'Koordinator'"
                              >
                                <div class="col-md-2" v-if="item.image_check">
                                  <img
                                    class="border"
                                    :src="item.image_check"
                                    alt="image_check"
                                    style="width: 100%"
                                  />
                                </div>
                              </div>
                            </div>
                          </label>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-md-6 mb-2">
                <a
                  href="javascript:window.history.go(-1);"
                  class="btn btn-default"
                  >Kembali</a
                >
              </div>
              <div class="col-md-6">
                <button
                  v-if="!detail.ticket_progress"
                  class="btn btn-success float-md-right"
                  @click="postData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Submit
                </button>
                <button
                  v-if="
                    detail.ticket_progress == 'Checked' &&
                    this.userLevel == 'Koordinator'
                  "
                  class="btn btn-success float-md-right"
                  @click="verifyData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Verify
                </button>
                <button
                  v-if="
                    detail.ticket_progress == 'Verify' &&
                    this.userLevel == 'SPV'
                  "
                  class="btn btn-success float-md-right"
                  @click="finishData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../plugins/userData";
import moment from "moment";

export default {
  name: "FormProcess",
  mixins: [userData],
  data() {
    return {
      detail: {
        id_ticket: "",
        no_ticket: "",
        ticket_created: "",
        subject_ticket: "",
        fill_ticket: "",
        nama_device: "",
        type_ticket: "",
        ticket_closed: "",
        nama_area: "",
        nama_lokasi: "",
        nama_sublokasi: "",
      },
      form: {
        id_ticket: "",
        ticket_progress: "Checked",
      },
      itemMaintenance: [],
      dataDipilih: [],
      loading: false,
      uploadProgress: {},
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.form.id_ticket = id;
      this.getDetail(id);
    }
  },
  computed: {
    groupedMaintenance() {
      return this.itemMaintenance.reduce((groups, item) => {
        const category = item.nama_cat_maintenance;
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(item);
        return groups;
      }, {});
    },
  },
  methods: {
    getDetail(id) {
      this.isLoading = true;
      axios
        .get("http://localhost/api-crm-dea/v2/ticket/detail/" + id, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.itemMaintenance = response.data.item_maintenance;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      formData.append("list_checked", JSON.stringify(this.dataDipilih));
      axios
        .post("http://localhost/api-crm-dea/v2/ticket/process/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    verifyData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post("http://localhost/api-crm-dea/v2/ticket/verify/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    finishData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post("http://localhost/api-crm-dea/v2/ticket/finish/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    updateSelectedData(idMaintenance) {
      const maintenance = this.itemMaintenance.find(
        (item) => item.id_maintenance === idMaintenance
      );
      if (maintenance) {
        if (maintenance.pilih) {
          this.dataDipilih.push({
            id_maintenance: idMaintenance,
          });
        } else {
          const indexToRemove = this.dataDipilih.findIndex(
            (item) => item.id_maintenance === idMaintenance
          );
          if (indexToRemove !== -1) {
            this.dataDipilih.splice(indexToRemove, 1);
          }
        }
      }
    },
    triggerFileInput(id) {
      document.getElementById("fileInput" + id).click();
    },
    uploadImage(event, id) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("id", id);
        formData.append("kode", this.detail.no_ticket);
        formData.append("ticket", this.detail.id_ticket);

        // Kirim request dengan Axios
        axios
          .post("http://localhost/api-crm-dea/v2/ticket/upload_image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // Perbarui progress untuk item yang sesuai
              this.$set(this.uploadProgress, id, percentCompleted);
            },
          })
          .then((response) => {
            console.log(response);
            this.uploadProgress = {};
            this.getDetail(this.form.id_ticket);
            // Sukses: Tindakan setelah berhasil mengunggah
            // alert("Image uploaded successfully for item: " + id);
          })
          .catch((error) => {
            // Error: Tindakan jika terjadi kesalahan
            console.error("Error uploading image:", error);
          });
      }
    },
    dateFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    timeFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("H:m");
      } else {
        return "-";
      }
    },
    datetimeFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("DD/MM/YYYY HH:mm");
      } else {
        return "-";
      }
    },
    calculateDuration(start, end) {
      if (end) {
        const date1 = moment(start);
        const date2 = moment(end);

        const diff = moment.duration(date2.diff(date1));
        const totalHours = Math.floor(diff.asHours());
        const minutes = diff.minutes();

        return `${totalHours}:${minutes}`;
      } else {
        return "Not Finish";
      }
    },
  },
};
</script>